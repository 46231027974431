import { useEffect, useState } from 'react';
import { useAdminControlsState } from '../../elements/adminControls/state/adminControls.state';

export const useLanguageControls = () => {
    const { setLanguageControls } = useAdminControlsState();

    const [init, setInit] = useState(false);

    useEffect(() => {
        if (!init) {
            const languageControls = localStorage.getItem('languageControls');
            if (languageControls) {
                setLanguageControls(languageControls === 'true');
            }
            setInit(true);
        }
    }, [init, setLanguageControls]);
};
