import { gql } from 'graphql-request';
import { useApi } from '../api/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IMedia } from '../../../../interfaces/cart/productInCart';
import { useRecoilValue } from 'recoil';
import { cmsSlugSelector } from '../../../frontend/areas/CMSPage/state/CMSPage.state';

interface ContentLayoutItemAddImageInput {
    itemID: string;
    file: File;
    tag?: string;
    languageCode: string; // Now mandatory
    config?: string;
    orderNo?: number;
}

export const useContentLayoutItemAddImageMutation = () => {
    const { postUpload } = useApi();

    const queryClient = useQueryClient();
    const slug = useRecoilValue(cmsSlugSelector);

    return useMutation<IMedia, unknown, ContentLayoutItemAddImageInput>({
        mutationFn: async (input) => {
            const { contentLayoutItemAddImage: result } = await postUpload<{
                contentLayoutItemAddImage: IMedia;
            }>(
                gql`
                    mutation (
                        $itemID: ID!
                        $file: Upload!
                        $tag: String
                        $languageCode: String!
                        $config: String
                        $orderNo: Int
                    ) {
                        contentLayoutItemAddImage(
                            itemID: $itemID
                            file: $file
                            tag: $tag
                            languageCode: $languageCode
                            config: $config
                            orderNo: $orderNo
                        ) {
                            id
                            tag
                            path
                            width
                            height
                            languageCode
                            orderNo
                        }
                    }
                `,
                {
                    itemID: input.itemID,
                    file: input.file,
                    tag: input.tag,
                    languageCode: input.languageCode,
                    config: input.config,
                    orderNo: input.orderNo,
                },
                { '0': ['variables.file'] },
                [input.file]
            );

            return result;
        },
        onSuccess: () => {
            const key = [`contentPage-${slug ?? '-'}`];
            void queryClient.invalidateQueries({ queryKey: key });
        },
    });
};
