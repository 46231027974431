import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { useSearchState } from './state/search.state';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../../general/theme/ComputedStyles/recoil/computedStyles.recoil';

export const Search: React.FC = () => {
    const tenantTheme = useRecoilValue(getTenantTheme);

    const { show, setShow } = useSearchState();

    return (
        <button
            className="relative my-auto flex lg:hidden"
            onClick={() => {
                setShow(!show);
            }}
        >
            <MagnifyingGlassIcon
                className="relative h-6 w-6 align-middle text-primary"
                style={{
                    color: tenantTheme.colors.primary.DEFAULT,
                }}
            />
        </button>
    );
};
