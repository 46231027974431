import { useEffect } from 'react';
import { useSidebarMobileState } from '../../sidebarMobile/state/sidebarMobile.state';

export const useSearchCloseHandling = () => {
    const { show, setShow } = useSidebarMobileState();

    useEffect(() => {
        if (!show) return;

        window.history.pushState({ modal: true }, '');

        const handlePopState = () => {
            setShow(false);
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [show, setShow]);

    useEffect(() => {
        if (!show) return;

        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setShow(false);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [show, setShow]);
};
