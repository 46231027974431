import { useCallback } from 'react';

export const useHexToRGBA = () => {
    // #ffea3e3e
    // #ea3e3e
    // rgba(234,62,62,1.000)
    // rgb(234,62,62)

    function hexToRgb(hex: string) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? {
                  r: parseInt(result[1] ?? 'ff', 16),
                  g: parseInt(result[2] ?? 'ff', 16),
                  b: parseInt(result[3] ?? 'ff', 16),
              }
            : null;
    }

    const convertHexToRGBA = useCallback((hex: string, opacity: number): string => {
        const splittedData = hexToRgb(hex); // {r: 234, g: 62, b: 62}

        if (splittedData && splittedData.r && splittedData.b && splittedData.g) {
            // Add opacity
            return `rgba(${splittedData?.r}, ${splittedData?.g}, ${
                splittedData?.b
            }, ${opacity.toString()})`;
        }

        return hex;
    }, []);

    return { convertHexToRGBA };
};
