import { useRecoilValue } from 'recoil';
import { tenantSelector } from '../../state/tenantState';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

export const useDefaultTenantBlockFrontend = () => {
    const tenant = useRecoilValue(tenantSelector);

    const { push, pathname } = useRouter();

    useEffect(() => {
        if (!tenant) return;

        if (tenant.name.toLowerCase() === 'default' && !pathname.startsWith('/admin')) {
            void push('/admin');
        }
    }, [pathname, push, tenant]);

    return {};
};
