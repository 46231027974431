import React from 'react';
import { imagePathSanitizer } from '../../../../../helper/imagePathSanitizer';
import { IMedia } from '../../../../../interfaces/cart/productInCart';
import { useCMSImageModalUtil } from '../hooks/useCMSImageModalUtil';

export interface ICMSImageUploadModalItemImage {
    media: IMedia[];
}

export const CMSImageUploadModalItemImage: React.FC<ICMSImageUploadModalItemImage> = (props) => {
    const { media } = props;

    const { calculateThumbnailDimensions } = useCMSImageModalUtil();

    return (
        <div className="flex max-h-40 w-max flex-shrink-0 items-center justify-center overflow-hidden rounded">
            {media[0] && media[0].path && media[0].width && media[0].height && (
                <div className="relative">
                    {/* Calculate dimensions keeping original aspect ratio but limited to container size */}
                    {(() => {
                        const dimensions = calculateThumbnailDimensions(
                            media[0].width,
                            media[0].height,
                            160
                        );
                        return (
                            <img
                                src={imagePathSanitizer(media[0].path)}
                                alt="Preview"
                                width={dimensions.width}
                                height={dimensions.height}
                                style={{
                                    width: `${dimensions.width}px`,
                                    height: `${dimensions.height}px`,
                                    maxWidth: '160px',
                                    maxHeight: '160px',
                                }}
                                className="object-contain"
                            />
                        );
                    })()}
                </div>
            )}
        </div>
    );
};
