import { atom, selector } from 'recoil';
import { IContentPage } from '../../../../../interfaces/CMS/cms.interfaces';

export interface ICMSPage {
    slug: string | null;
    pageData: IContentPage | null;
    showAdminControls?: boolean;
}

export const cmsPageRecoil = atom<ICMSPage>({
    key: `cms-page-recoil`,
    default: { slug: null, pageData: null, showAdminControls: false },
});

// pageData
export const cmsPageDataSelector = selector<IContentPage | null>({
    key: 'cmsPageDataSelector',
    get: ({ get }) => {
        return get(cmsPageRecoil).pageData;
    },
    set: ({ set }, newValue) => {
        set(cmsPageRecoil, (oldValue) => {
            return {
                ...oldValue,
                pageData: newValue as IContentPage | null,
            };
        });
    },
});

export const cmsSlugSelector = selector<string | null>({
    key: 'cmsSlugSelector',
    get: ({ get }) => {
        return get(cmsPageRecoil).slug;
    },
    set: ({ set }, newValue) => {
        set(cmsPageRecoil, (oldValue) => {
            return {
                ...oldValue,
                slug: newValue as string | null,
            };
        });
    },
});
