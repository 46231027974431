import React, { PropsWithChildren, useCallback } from 'react';
import { useSidebarMobileState } from '../state/sidebarMobile.state';
import { useTranslation } from '../../../../general/Translations/hooks/useTranslation';
import { RippleButton } from '../../../../general/controls/button/RippleButton';
import { useThemeStyles } from '../../../../general/theme/TenantTheme/hooks/useThemeStyles';

export interface ISidebarLink extends PropsWithChildren {
    testId: string;
    href?: string;
    target?: string;
    onClick?: () => void;
    labelTranslationKey?: string;
}

export const SidebarLink: React.FC<ISidebarLink> = (props) => {
    const { testId = '', target, onClick, href, labelTranslationKey } = props;

    const { setShow } = useSidebarMobileState();
    const { getT } = useTranslation();

    const { applyStyles } = useThemeStyles();

    const close = useCallback(() => {
        setShow(false);
    }, [setShow]);

    return (
        <>
            <div key={`popover-item-${testId ?? ''}`} className="flex w-full">
                <RippleButton
                    onClick={() => {
                        if (onClick) {
                            onClick();
                        }
                        close();
                    }}
                    href={href ?? undefined}
                    target={target ?? undefined}
                    testId={testId ? `popover-item-${testId}` : undefined}
                >
                    <span
                        className="font-semibold"
                        style={{
                            ...applyStyles({
                                textColor: 'colors.primary.DEFAULT',
                            }),
                        }}
                    >
                        {getT(labelTranslationKey ?? '')}
                    </span>
                </RippleButton>
            </div>
        </>
    );
};
