import React from 'react';
import { useSearchState } from './state/search.state';
import { Transition } from '@headlessui/react';
import { useSearchFilteredProducts } from './hooks/useSearchFilteredProducts';
import { CatalogItem } from '../../elements/CatalogItem/CatalogItem';
import { SearchField } from './SearchField';
import { useTranslation } from '../../../general/Translations/hooks/useTranslation';
import { useTranslationApply } from '../../../general/Translations/hooks/useTranslationApply';
import { useSearchCloseHandling } from './hooks/useSearchCloseHandling';

export const SearchModal: React.FC = () => {
    const { show, setShow, searchTerm } = useSearchState();

    const { filteredProducts } = useSearchFilteredProducts();

    const { getT } = useTranslation();
    const { applyTranslation } = useTranslationApply();

    useSearchCloseHandling();

    return (
        <>
            <Transition
                show={show}
                appear={show}
                as={'div'}
                className="fixed top-0 left-0 w-screen h-[100dvh] z-20"
                enter="transition transform ease-in-out duration-400"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition transform ease-in-out duration-400"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="relative flex flex-col gap-3 h-full w-full bg-gradient-to-tr from-stone-700/50 via-neutral-500/30 to-stone-400/0 p-4 pt-20 backdrop-blur">
                    <SearchField />

                    {searchTerm !== '' && filteredProducts.length === 0 && (
                        <div className="bg-neutral-100/90 p-4 rounded text-neutral-500">
                            <span
                                {...applyTranslation({
                                    key: 'infoNoProductsFound',
                                })}
                            >
                                {getT('infoNoProductsFound')}
                            </span>
                        </div>
                    )}

                    {searchTerm === '' && (
                        <div className="bg-neutral-100/90 p-4 rounded text-neutral-500">
                            <span
                                {...applyTranslation({
                                    key: 'infoTypeToSearch',
                                })}
                            >
                                {getT('infoTypeToSearch')}
                            </span>
                        </div>
                    )}

                    <section className="relative grid w-full grid-cols-2 gap-3 md:grid-cols-3 lg:grid-cols-4">
                        {filteredProducts.length > 0 &&
                            filteredProducts.map((product) => {
                                return (
                                    <CatalogItem
                                        product={product}
                                        key={`search-result-item-${product.id}`}
                                        showBorder={true}
                                        small={true}
                                        shortText={true}
                                        showIconBar={false}
                                        onClick={() => {
                                            setShow(false);
                                        }}
                                    />
                                );
                            })}
                    </section>
                </div>
            </Transition>
        </>
    );
};
