import React, { useMemo } from 'react';
import { Button } from '../../../general/controls/button/Button';
import { EnvelopeIcon } from '@heroicons/react/16/solid';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../../general/theme/ComputedStyles/recoil/computedStyles.recoil';
import { useHexToRGBA } from '../../../general/hooks/helper/useHexToRGBA';
import { useWindowDimensions } from '../../../general/hooks/helper/useWindowDimensions';
import { RichTextDisplay } from '../../../general/controls/RichText/RichTextDisplay';
import Link from 'next/link';
import { useTranslationApply } from '../../../general/Translations/hooks/useTranslationApply';
import { useTranslation } from '../../../general/Translations/hooks/useTranslation';
import { useTenantFields } from '../../../general/hooks/tenant/useTenantFields';

interface ISupportFields {
    headline: string | undefined | boolean;
    description: string | undefined | boolean;
    phone: string | undefined | boolean;
    fax: string | undefined | boolean;
    email: string | undefined | boolean;
    buttonLabel: string | undefined | boolean;
}

interface ISupportCTABox {}

export const SupportCTABox: React.FC<ISupportCTABox> = () => {
    const tenantTheme = useRecoilValue(getTenantTheme);
    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();

    const { getTenantField } = useTenantFields();

    const { convertHexToRGBA } = useHexToRGBA();
    const { isMobile } = useWindowDimensions();

    const supportFields: ISupportFields = useMemo(() => {
        return {
            headline: getTenantField('supportHeadline') ?? 'Haben Sie Fragen?',
            description:
                getTenantField('supportDescription') ??
                'Wir helfen Ihnen gerne weiter. Kontaktieren Sie uns bei Fragen oder Anliegen. Wir freuen uns auf Ihre Nachricht.',
            phone: getTenantField('supportPhone') ?? '+49 0000 000000',
            fax: getTenantField('supportFax') ?? '',
            email: getTenantField('supportEmail') ?? '',
            buttonLabel: getTenantField('supportButtonLabel') ?? 'Kontaktieren Sie uns',
        };
    }, [getTenantField]);

    return (
        <>
            {/* CTA Box */}
            <div
                className={`relative flex h-max w-[300px] flex-col items-center justify-center gap-4 rounded border-2 border-primary/20 bg-white/30 p-4 ${
                    isMobile ? 'mt-10' : ''
                }`}
                style={{
                    background: convertHexToRGBA(tenantTheme.colors.background.primaryLight, 0.1),
                }}
            >
                <span
                    className="text-lg font-semibold text-primary-dark"
                    style={{ color: tenantTheme.colors.primary.dark }}
                >
                    <span
                        {...applyTranslation({
                            key: 'footerAnyQuestionsLabel',
                        })}
                    >
                        {getT('footerAnyQuestionsLabel') ?? 'Haben Sie Fragen?'}
                    </span>
                </span>

                <span className="pb-2 t text-balance">
                    <RichTextDisplay content={supportFields.description as never} />
                </span>
                <Button
                    color="primary"
                    onClick={() => {}}
                    iconStart={<EnvelopeIcon className="h-4" />}
                    href={`mailto:${supportFields?.email ?? ''}`}
                >
                    <span
                        {...applyTranslation({
                            key: 'footerContactUs',
                        })}
                    >
                        {getT('footerContactUs') ?? 'Kontaktieren Sie uns!'}
                    </span>
                </Button>

                <div className="relative flex w-full flex-col items-center gap-1">
                    {/* Tenant Phone */}
                    {supportFields?.phone && (supportFields.phone as string)?.length > 3 && (
                        <Link href={`tel:${supportFields?.phone ?? ''}`}>
                            <span
                                style={{
                                    color: tenantTheme.colors.primary.DEFAULT,
                                }}
                            >
                                {supportFields?.phone ?? '+49 0000 000000'}
                            </span>
                        </Link>
                    )}

                    {supportFields?.fax && (supportFields?.fax as string).length > 3 && (
                        <Link href={`tel:${supportFields?.fax ?? ''}`}>
                            <span
                                style={{
                                    color: tenantTheme.colors.primary.DEFAULT,
                                }}
                            >
                                {supportFields?.fax ?? '+49 0000 000000'}
                            </span>
                        </Link>
                    )}
                </div>
            </div>
        </>
    );
};
