import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../../general/theme/ComputedStyles/recoil/computedStyles.recoil';
import { CompanyHeader } from '../../../general/elements/companyHeader/CompanyHeader';
import { tenantSelector } from '../../../general/state/tenantState';
import { EFooterLayoutEnum } from './enum/footerLayout.enum';
import { FooterLayout3ColumnSupport } from './layouts/FooterLayout3ColumnSupport';
import { FooterLayout4Column } from './layouts/FooterLayout4Column';
import { FooterLegalLine } from './FooterLegalLine';

export interface IFooter {}

export const Footer: React.FC<IFooter> = () => {
    const tenantTheme = useRecoilValue(getTenantTheme);

    const tenant = useRecoilValue(tenantSelector);

    const footerLayout: EFooterLayoutEnum = useMemo(() => {
        return (tenant?.fields?.footerLayout as EFooterLayoutEnum) ?? EFooterLayoutEnum['4Columns'];
    }, [tenant?.fields?.footerLayout]);

    const showLegalLine = useMemo(() => {
        switch (footerLayout) {
            case EFooterLayoutEnum.hide:
                return false;
            default:
                return true;
        }
    }, [footerLayout]);

    return (
        <>
            <footer
                className={`relative mx-auto flex h-full w-screen max-w-7xl flex-col items-center justify-start bg-white px-4 pb-10  md:min-h-[100px] md:items-start md:px-10 md:py-10`}
            >
                {/* Logo Tenant - TODO HIDDEN */}
                <div className="relative hidden h-max w-max">
                    <CompanyHeader
                        src={tenantTheme.brand.logo.url}
                        companyName={tenantTheme.brand.companyName ?? ''}
                        companyClaim={tenantTheme.brand.claim ?? ''}
                        testId="companyHeader-Footer"
                    />
                </div>

                {/* Info Wrapper */}
                {footerLayout === EFooterLayoutEnum['3ColumnsSupport'] && (
                    <FooterLayout3ColumnSupport />
                )}
                {footerLayout === EFooterLayoutEnum['4Columns'] && <FooterLayout4Column />}

                {showLegalLine && <FooterLegalLine />}
            </footer>
        </>
    );
};
