import { MouseEvent, useCallback, useEffect, useRef } from 'react';
import { useTranslationEditorState } from '../state/TranslationEditor.state';
import { useAdminControlsState } from '../../elements/adminControls/state/adminControls.state';
import { ETranslationEntity } from '../types/translationApply.types';
import { ETranslationType } from '../enum/translations.enums';

const useTranslationObserver = () => {
    const { set: setTranslationState } = useTranslationEditorState();
    const { languageControls, showFAB } = useAdminControlsState();
    
    const handlers = useRef(new Map<HTMLElement, (event: Event) => void>());
    const observerRef = useRef<MutationObserver | null>(null);
    const isActiveRef = useRef(false);

    const openTranslationModal = useCallback(
        (event: MouseEvent) => {
            const element = event.target as HTMLElement;
            const translationType = element.getAttribute('data-translation-type') ?? 'static';
            const translationKey = element.getAttribute('data-translation') ?? 'x';
            const translationEntity = element.getAttribute('data-translation-entity') ?? 'text';

            setTranslationState({
                open: true,
                translationType: translationType as ETranslationType,
                translationKey: translationKey.split(','),
                translationEntity: translationEntity as ETranslationEntity,
            });
        },
        [setTranslationState]
    );

    const cleanupHandlers = useCallback(() => {
        for (const [element, handler] of handlers.current) {
            if (element) {
                element.removeEventListener('click', handler);
                element.classList.remove('translationTag');
                delete element.dataset.translationBound;
            }
        }
        handlers.current.clear();
    }, []);

    const bindTranslationElement = useCallback((element: Element) => {
        if (!(element instanceof HTMLElement)) return;
        
        if (element.getAttribute('data-translation-locked') === 'true') {
            return;
        }

        if (element.dataset.translationBound === 'true') {
            return;
        }

        element.classList.add('translationTag');
        
        const handleClick = (event: Event) => {
            event.preventDefault();
            event.stopPropagation();
            openTranslationModal(event as unknown as MouseEvent);
        };
        
        element.addEventListener('click', handleClick);
        element.dataset.translationBound = 'true';
        handlers.current.set(element, handleClick);
    }, [openTranslationModal]);

    const processElements = useCallback(() => {
        const elements = document.querySelectorAll('[data-translation]:not([data-translation-bound="true"])');
        elements.forEach(bindTranslationElement);
    }, [bindTranslationElement]);

    useEffect(() => {
        const isActive = languageControls && showFAB;
        
        if (isActive === isActiveRef.current) return;
        isActiveRef.current = isActive;

        if (isActive) {
            cleanupHandlers();
            
            processElements();
            
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
            
            observerRef.current = new MutationObserver((mutations) => {
                let needsProcessing = false;
                
                for (const mutation of mutations) {
                    if (mutation.type === 'childList') {
                        needsProcessing = true;
                        break;
                    }
                }
                
                if (needsProcessing) {
                    window.requestAnimationFrame(() => {
                        processElements();
                    });
                }
            });

            observerRef.current.observe(document.body, {
                childList: true,
                subtree: true,
            });
        } else {
            if (observerRef.current) {
                observerRef.current.disconnect();
                observerRef.current = null;
            }
            
            cleanupHandlers();
        }
        
        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [languageControls, showFAB, cleanupHandlers, processElements]);

    // Process elements on each render when active
    useEffect(() => {
        if (isActiveRef.current) {
            processElements();
        }
    });

    return null;
};

export default useTranslationObserver;
