import React, { useMemo } from 'react';
import Link from 'next/link';
import { useTranslationApply } from '../../../general/Translations/hooks/useTranslationApply';
import { useTranslation } from '../../../general/Translations/hooks/useTranslation';
import { useThemeStyles } from '../../../general/theme/TenantTheme/hooks/useThemeStyles';
import { useRecoilValue } from 'recoil';
import { tenantSelector } from '../../../general/state/tenantState';

interface IFooterLegalLine {}

export const FooterLegalLine: React.FC<IFooterLegalLine> = () => {
    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();
    const tenant = useRecoilValue(tenantSelector);
    const { applyStyles } = useThemeStyles();

    const linkLegal = useMemo(() => {
        // Always have the prod fallback, due to legal reasons
        return '/page?slug=legal';
    }, []);

    const linkPrivacy = useMemo(() => {
        // Always have the prod fallback, due to legal reasons
        return '/page?slug=privacy';
    }, []);

    const linkPrivacyGtc = useMemo(() => {
        // Always have the prod fallback, due to legal reasons
        return '/page?slug=gtc';
    }, []);

    return (
        <>
            {/* Legal Notice and Privacy Policy */}
            <div
                className="relative mx-auto flex w-max flex-row gap-20 pt-10"
                style={{
                    ...applyStyles({
                        textColor: 'colors.body.light',
                    }),
                }}
            >
                <Link
                    href={linkLegal}
                    className="transition-all text-tertiary focus:text-tertiary text-[13px] hover:font-bold"
                    target="_blank"
                    aria-label={`${tenant?.name ?? ''} Legal Website`}
                    aria-describedby={`After clicking you´ll be redirected to ${linkLegal}`}
                    data-test-id="link-imprint"
                >
                    <span
                        {...applyTranslation({
                            key: 'footerImprint',
                        })}
                    >
                        {getT('footerImprint') ?? 'Impressum'}
                    </span>
                </Link>
                <Link
                    href={linkPrivacy}
                    className="transition-all text-tertiary focus:text-tertiary text-[13px] hover:font-bold"
                    target="_blank"
                    aria-label={`${tenant?.name ?? ''} Privacy policy`}
                    aria-describedby={`After clicking you´ll be redirected to ${linkPrivacy}`}
                    data-test-id="link-privacy"
                >
                    <span
                        {...applyTranslation({
                            key: 'footerPrivacyPolicy',
                        })}
                    >
                        {getT('footerPrivacyPolicy') ?? 'Datenschutz'}
                    </span>
                </Link>
                <Link
                    href={linkPrivacyGtc}
                    className="transition-all text-tertiary focus:text-tertiary text-[13px] hover:font-bold"
                    target="_blank"
                    aria-label={`${tenant?.name ?? ''} GTC`}
                    aria-describedby={`After clicking you´ll be redirected to ${linkPrivacyGtc}`}
                    data-test-id="link-gtc"
                >
                    <span
                        {...applyTranslation({
                            key: 'footerPrivacyGtc',
                        })}
                    >
                        {getT('footerPrivacyGtc') ?? 'AGB'}
                    </span>
                </Link>
            </div>
        </>
    );
};
