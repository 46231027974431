import React, { useMemo } from 'react';
import { IMedia } from '../../../../../interfaces/cart/productInCart';
import { ArrowPathIcon, TrashIcon } from '@heroicons/react/16/solid';
import { DropZone } from '../../../../general/controls/DropZone/DropZone';
import { useCMSImageModalUtil } from '../hooks/useCMSImageModalUtil';
import { useCMSImageUploaderState } from '../state/CMSImageUploader.state';
import { useContentLayoutItemRemoveImageMutation } from '../../../../general/hooks/pageCMSLayouts/useContentLayoutItemRemoveImage';
import { useTranslation } from '../../../../general/Translations/hooks/useTranslation';
import { CMSImageUploadModalItemImage } from './CMSImageUploadModalItemImage';
import { IconButton } from '../../../../general/controls/button/IconButton';
import { Chip } from '../../../../general/elements/Chips/Chip';

export interface ICMSImageUploadModalItem {
    languageCode?: string;
}

export const CMSImageUploadModalItem: React.FC<ICMSImageUploadModalItem> = (props) => {
    const { languageCode } = props;

    const { isPending: isPendingRemove } = useContentLayoutItemRemoveImageMutation();
    const { getT } = useTranslation();

    const {
        media,
        mediaTranslations,
        mediaTranslation,
        allowMoreImages,
        imageHint,
        requiredRatio,
    } = useCMSImageUploaderState();

    const { handleDelete, handleDrop, handleClear } = useCMSImageModalUtil();

    const isDefaultLanguage = !languageCode;
    let currentMedia: IMedia[] | undefined;

    if (isDefaultLanguage) {
        // For default language tab, use the media array
        currentMedia = media;
    } else {
        // For language-specific tabs, prioritize mediaTranslation (nested format)
        if (mediaTranslation) {
            const translationObject = mediaTranslation.find((t) => t.languageCode === languageCode);
            if (translationObject && translationObject.media) {
                currentMedia = translationObject.media;
            }
        }

        // If not found in mediaTranslation, try mediaTranslations array
        if (!currentMedia || currentMedia.length === 0) {
            const translationsMedia = mediaTranslations?.filter(
                (m) => m.languageCode === languageCode
            );
            if (translationsMedia && translationsMedia.length > 0) {
                currentMedia = translationsMedia;
            }
        }
    }

    const fileTypeMimeOrEnding = useMemo(() => {
        if (currentMedia && currentMedia.length > 0 && currentMedia[0]?.mime) {
            return currentMedia?.[0]?.mime;
        }

        // If not mime available read the fileending of path
        if (currentMedia && currentMedia.length > 0 && currentMedia[0]?.path) {
            const path = currentMedia[0].path;
            const pathParts = path.split('.');
            return pathParts[pathParts.length - 1];
        }
    }, [currentMedia]);

    const imageFitRequiredRetio: boolean = useMemo(() => {
        if (
            currentMedia &&
            currentMedia.length > 0 &&
            currentMedia[0]?.width &&
            currentMedia[0]?.height &&
            requiredRatio?.width &&
            requiredRatio?.height
        ) {
            const imageRatio = currentMedia[0].width / currentMedia[0].height;
            const requiredImageRatio = requiredRatio.width / requiredRatio.height;

            // Allow a small tolerance for rounding errors (0.01 or 1%)
            const tolerance = 0.01;
            return Math.abs(imageRatio - requiredImageRatio) <= tolerance;
        }

        return false;
    }, [currentMedia, requiredRatio]);

    return (
        <div className="flex w-full flex-col">
            {/* Current image preview and actions */}
            {currentMedia && currentMedia.length > 0 && (
                <div className="mb-4 flex flex-col items-start gap-4">
                    <div className="relative flex flex-row gap-4">
                        <CMSImageUploadModalItemImage media={currentMedia} />
                        <div className="relative flex flex-col justify-end gap-2">
                            <IconButton
                                disabled={isPendingRemove}
                                iconSpin={isPendingRemove}
                                onClick={() =>
                                    currentMedia[0] &&
                                    handleDelete(currentMedia[0].id, languageCode)
                                }
                                icon={
                                    isPendingRemove ? (
                                        <ArrowPathIcon className="h-5" />
                                    ) : (
                                        <TrashIcon className="h-5 text-danger" />
                                    )
                                }
                            />

                            <div className="flex flex-col justify-end gap-3">
                                <div className="text-sm text-gray-600">
                                    {currentMedia[0] &&
                                        currentMedia[0].width &&
                                        currentMedia[0].height && (
                                            <span className="mb-1 block">
                                                {currentMedia[0].width} × {currentMedia[0].height}{' '}
                                                px
                                            </span>
                                        )}
                                    {fileTypeMimeOrEnding && (
                                        <div className="inline-flex gap-1">
                                            <span>Typ:</span>
                                            <span className="font-semibold">
                                                {fileTypeMimeOrEnding.toUpperCase()}
                                            </span>
                                        </div>
                                    )}
                                    <div className="mt-2">
                                        {imageFitRequiredRetio && (
                                            <Chip color="success">Passt</Chip>
                                        )}
                                        {!imageFitRequiredRetio && (
                                            <Chip color="danger">Bild passt nicht</Chip>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Upload area */}
            {(!currentMedia || currentMedia.length === 0 || allowMoreImages) && (
                <div className="w-full">
                    <DropZone
                        onDrop={(files) => handleDrop(files, languageCode)}
                        onClear={handleClear}
                        testId={`drop-zone-${languageCode || 'default'}`}
                        accept={{ 'image/*': ['.png', '.jpeg', '.jpg'] }}
                        text={
                            imageHint
                                ? imageHint
                                : (getT('dropImageHint') ?? 'Bild hier ablegen oder klicken')
                        }
                    />
                </div>
            )}
        </div>
    );
};
