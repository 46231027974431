import React, { useMemo } from 'react';
import { useCatalog } from '../../catalog/hooks/useCatalog';
import { FooterCategoryLink } from '../elements/FooterCategoryLink';

export const FooterLayout4Column: React.FC = () => {
    const { categories } = useCatalog();

    const topLevelCategories = useMemo(() => {
        // filter all categories which does have no parentCategoryID
        return categories
            .filter((category) => {
                return (
                    category.parentCategoryID === null || category.parentCategoryID === undefined
                );
            })
            .slice(0, 4);
    }, [categories]);

    return (
        <>
            <div className="relative flex h-full w-full flex-col items-center gap-4">
                <div className="flex h-full w-full flex-row gap-10 overflow-x-hidden">
                    {topLevelCategories.map((category, index) => {
                        return (
                            <div key={index} className="relative flex w-full flex-col gap-10">
                                <FooterCategoryLink category={category} isParent={true} />

                                <div className="relative flex flex-col gap-5">
                                    {category.children?.map((item, id) => {
                                        return (
                                            <FooterCategoryLink
                                                key={`${id}-${item.id}`}
                                                category={item}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};
