import { create } from 'zustand';
import { IContentLayout } from '../../../../../interfaces/CMS/cms.interfaces';
import { IMedia } from '../../../../../interfaces/cart/productInCart';
import { ILocale } from '../../../../../interfaces/localisation/locale.interfaces';

export type TCMSImageRequiredRatio = {
    width: number;
    height: number;
};

interface ICMSImageUploader {
    show: boolean;
    layout?: IContentLayout;
    itemIndex?: number;
    media?: IMedia[];
    mediaTranslations?: IMedia[];
    mediaTranslation?: Array<{
        languageCode: string;
        media: IMedia[];
    }>;
    allowMoreImages: boolean;
    imageHint?: string;
    showCTASettings?: boolean;
    hasContentAccess: boolean;
    availableLanguages: ILocale[];
    defaultLanguage?: string;

    requiredRatio?: TCMSImageRequiredRatio;
    setRequiredRatio?: (ratio: { width: number; height: number }) => void;

    setShow: (show: boolean) => void;
    setData: (data: Partial<ICMSImageUploader>) => void;
    reset: () => void;
}

export const useCMSImageUploaderState = create<ICMSImageUploader>((set) => ({
    show: false,
    allowMoreImages: false,
    hasContentAccess: false,
    availableLanguages: [],

    requiredRatio: undefined,
    setRequiredRatio: (ratio) => {
        set({ requiredRatio: ratio });
    },

    setShow: (show) => {
        set({ show });
    },
    setData: (data) => {
        set(data);
    },
    reset: () => {
        set({
            show: false,
            layout: undefined,
            itemIndex: undefined,
            media: undefined,
            mediaTranslations: undefined,
            mediaTranslation: undefined,
            allowMoreImages: false,
            imageHint: undefined,
            showCTASettings: undefined,
            hasContentAccess: false,
            availableLanguages: [],
            defaultLanguage: undefined,
        });
    },
}));
