import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../../general/theme/ComputedStyles/recoil/computedStyles.recoil';
import { useThemeStyles } from '../../../general/theme/TenantTheme/hooks/useThemeStyles';
import { Bars3Icon } from '@heroicons/react/20/solid';
import { useSidebarMobileState } from '../sidebarMobile/state/sidebarMobile.state';
import { CompanyHeaderLogoOnly } from '../../../general/elements/companyHeader/CompanyHeaderLogoOnly';
import { CompanyHeader } from '../../../general/elements/companyHeader/CompanyHeader';
import { CartIndicatorBubble } from '../header/elements/CartIndicatorBubble/CartIndicatorBubble';
import { Search } from '../search/Search';

export interface IHeaderMobile {
    hideCategoryNavigation?: boolean;
    border?: boolean;
}

export const HeaderMobile: React.FC<IHeaderMobile> = (props) => {
    const { border } = props;

    const tenantTheme = useRecoilValue(getTenantTheme);
    const { applyStyles } = useThemeStyles();

    const { setShow } = useSidebarMobileState();

    const borderStyles = useMemo(() => {
        if (border) {
            return 'border-b-2';
        }
        return '';
    }, [border]);

    const showWideLogoHeader = useMemo(() => {
        return tenantTheme.header.showWideLogoHeader ?? false;
    }, [tenantTheme.header.showWideLogoHeader]);

    return (
        <div
            id="Header"
            className={`relative flex flex-row w-screen items-center gap-8 px-3 py-2 justify-center md:h-max shadow ${borderStyles}`}
            data-test-id="header-mobile"
            style={{
                ...applyStyles({
                    bgColor: 'colors.applications.header.background.DEFAULT',
                    borderColor: 'colors.applications.header.border.DEFAULT',
                }),
            }}
        >
            <div className="w-full">
                {showWideLogoHeader && (
                    <div className="relative max-w-[100px]">
                        <CompanyHeaderLogoOnly
                            src={tenantTheme.brand.logoWide?.url}
                            height="30px"
                        />
                    </div>
                )}
                {!showWideLogoHeader && (
                    <CompanyHeader
                        src={tenantTheme.brand.logo.url}
                        companyName={tenantTheme.brand.companyName ?? ''}
                        companyClaim={tenantTheme.brand.claim ?? ''}
                    />
                )}
            </div>

            <div className="relative w-max">
                <CartIndicatorBubble />
            </div>

            <div>
                <Search />
            </div>

            <div className="">
                <button
                    className="relative flex h-full w-10 items-center justify-center p-1"
                    onClick={() => setShow(true)}
                >
                    <Bars3Icon className="h-6 w-6" />
                </button>
            </div>
        </div>
    );
};
