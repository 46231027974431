import React, { useMemo, useState } from 'react';
import { RippleButton } from '../../controls/button/RippleButton';
import { Cog6ToothIcon } from '@heroicons/react/20/solid';
import { UserContextToggle } from '../UserContextMenu/elements/UserContextToggle';
import { Transition } from '@headlessui/react';
import { useAdminControlsState } from './state/adminControls.state';
import { EAccess } from '../../../../interfaces/role/IAccess';
import { useAccess } from '../../access/hooks/useAccess';

interface IAdminControlsFAB {}

export const AdminControlsFAB: React.FC<IAdminControlsFAB> = () => {
    const [open, setOpen] = useState(false);

    const { hasAccess } = useAccess();

    // ⌘+k pr CTRL+k
    const placeholderSuffix = useMemo(() => {
        const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
        return isMac ? '⌘+L' : 'CTRL+L';
    }, []);

    const {
        showFAB,
        productControls,
        setProductControls,
        languageControls,
        setLanguageControls,
        cmsControls,
        setCmsControls,
    } = useAdminControlsState();

    return (
        <>
            {showFAB && (
                <div className="fixed bottom-5 left-5 z-50">
                    <div className="relative flex flex-col items-center gap-1 overflow-y-visible">
                        <RippleButton
                            onClick={() => {
                                setOpen(!open);
                            }}
                            wrapperCss="bg-cloudbar aspect-[1/1] rounded-full shadow cursor-pointer w-10 h-10 relative flex"
                        >
                            <div className="flex h-10 w-10 items-center justify-center">
                                <Cog6ToothIcon className="h-6 w-6 text-white" />
                            </div>
                        </RippleButton>

                        <Transition
                            show={open}
                            appear={open}
                            as="section"
                            className="absolute bottom-14 left-0 flex w-max flex-col gap-4 rounded border border-neutral-300 bg-neutral-50 p-4 shadow"
                            enter="transition transform ease-in-out duration-200"
                            enterFrom="opacity-0 translate-y-4"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition transform ease-in-out duration-100 translate-y-0"
                            leaveFrom="opacity-100 translate-y-4"
                            leaveTo="opacity-0"
                        >
                            {hasAccess([EAccess.mProd]) && (
                                <UserContextToggle
                                    labelSmall={'Produkte'}
                                    label={'Produkte & Kategorien'}
                                    description="Produkte, Kategorien, Produktfelder und Produkt-Metafelder."
                                    set={(checked) => {
                                        setProductControls(checked);
                                        localStorage.setItem('productControls', checked.toString());
                                    }}
                                    checked={productControls}
                                />
                            )}
                            {hasAccess([EAccess.mContent]) && (
                                <UserContextToggle
                                    labelSmall={'CMS'}
                                    label={'CMS'}
                                    description="Content Management System, wie Seiten, Layout-Elemente und Content-Inserts."
                                    set={(checked) => {
                                        setCmsControls(checked);
                                        localStorage.setItem('cmsControls', checked.toString());
                                    }}
                                    checked={cmsControls}
                                />
                            )}
                            {hasAccess([EAccess.mLocalisation]) && (
                                <UserContextToggle
                                    labelSmall={'Lokalisierung'}
                                    label={'Lokalisierung'}
                                    description="Es geht um die Lokalisierung von Buttons, Labels und ähnlichen Elementen, die zum System gehören. Aber dynamische Inhalte sind davon ausgenommen."
                                    set={(checked) => {
                                        setLanguageControls(checked);
                                        localStorage.setItem(
                                            'languageControls',
                                            checked.toString()
                                        );
                                    }}
                                    checked={languageControls}
                                />
                            )}
                            <span className="text-sm text-neutral-400">
                                Ein-/ausblenden{' '}
                                <span className="font-semibold text-neutral-500">
                                    {placeholderSuffix}
                                </span>
                            </span>
                        </Transition>
                    </div>
                </div>
            )}
            {open && (
                <div
                    className="fixed top-0 left-0 z-40 flex h-screen w-screen bg-neutral-50/50"
                    onClick={() => {
                        setOpen(false);
                    }}
                />
            )}
        </>
    );
};
