import { IUser } from '../interfaces/IUser';

export const getUsersFullName = (user: IUser): string => {
    if (user?.fields?.generalFirstName && user?.fields?.generalLastName) {
        return `${user?.fields?.generalFirstName ?? ''} ${user?.fields?.generalLastName ?? ''}`;
    }

    return user?.userName ?? '';
};

export const getUsersShortName = (user: IUser): string => {
    if (user?.fields?.generalFirstName && user?.fields?.generalLastName) {
        return `${user?.fields?.generalFirstName ?? ''} ${user?.fields?.generalLastName ?? ''}`;
    }

    return user?.userName ?? '';
};
