import { Transition } from '@headlessui/react';
import React from 'react';
import { useSidebarMobileState } from './state/sidebarMobile.state';
import { ChevronDoubleLeftIcon } from '@heroicons/react/24/solid';
import { SidebarLink } from './elements/SidebarLink';
import { useSidebarMenuItems } from './hooks/useSidebarMenuItems';
import { useSidebarCloseHandling } from './hooks/useSidebarCloseHandling';
import { SidebarUserDisplay } from './elements/SidebarUserDisplay';

interface ISidebarMobile {}

export const SidebarMobile: React.FC<ISidebarMobile> = () => {
    const { show, setShow } = useSidebarMobileState();

    const { navItems } = useSidebarMenuItems();

    useSidebarCloseHandling();

    return (
        <>
            {show && (
                <div className="fixed top-0 left-0 z-50 flex h-screen w-screen bg-black/30 bg-gradient-to-tr from-stone-400 via-neutral-400 to-stone-300 shadow-xl backdrop-blur-md"></div>
            )}

            <Transition
                as={'div'}
                className="fixed top-0 left-0 flex h-[100dvh] w-screen items-center max-w-[800px] z-[55] overflow-x-hidden"
                show={show}
                appear={show}
                enter="transition ease-in-out duration-400"
                enterFrom="transform opacity-0 -translate-x-full"
                enterTo="transform opacity-100 translate-x-0"
                leave="transition ease-in duration-200"
                leaveFrom="transform opacity-100 translate-x-0"
                leaveTo="transform opacity-0 -translate-x-full"
            >
                <div className="relative h-[100dvh] min-w-[300px] w-[calc(100%-50px)] bg-neutral-100 shadow-xl">
                    {/* Close Button */}
                    <button
                        className="absolute top-0 right-0 m-4 translate-x-[155%]"
                        onClick={() => setShow(false)}
                    >
                        <ChevronDoubleLeftIcon className="h-9 w-9 text-neutral-500" />
                    </button>
                    {/* Main Section Links */}
                    <section className="relative flex flex-col gap-1 w-full h-full">
                        {/* Main Links */}
                        <div className="relative h-full flex flex-col gap-4 p-4">
                            {navItems.map((navItem) => {
                                return (
                                    <SidebarLink
                                        labelTranslationKey={navItem.translationKey}
                                        href={navItem.href}
                                        testId={navItem.id ?? ''}
                                        key={navItem.id}
                                    />
                                );
                            })}
                        </div>

                        <div>
                            <SidebarUserDisplay />
                        </div>
                    </section>
                </div>
            </Transition>
        </>
    );
};
