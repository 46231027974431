import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import Image from 'next/legacy/image';
import { nanoid } from 'nanoid';
import { CheckIcon, CloudArrowUpIcon, DocumentIcon, XMarkIcon } from '@heroicons/react/16/solid';

interface IDropZone {
    executeOnDrop?: boolean;
    text?: string;
    icon?: ReactElement<unknown>;
    iconAccepted?: ReactElement<unknown>;
    iconNotAllowed?: ReactElement<unknown>;
    onDrop?: (acceptedFiles: File[]) => void;
    onClear?: () => void;
    accept?: Accept | undefined;
    previewImage?: string;
    disabled?: boolean;
    testId: string;
    darkBg?: boolean;
}

/**
 * General DropZone React Component with Tailwind
 */
export const DropZone: React.FC<IDropZone> = (props) => {
    const {
        executeOnDrop = true,
        icon = <CloudArrowUpIcon />,
        iconAccepted = <CheckIcon />,
        iconNotAllowed = <XMarkIcon />,
        text,
        onDrop,
        onClear,
        accept = {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/jpg': ['.jpg', '.jpeg'],
        },
        previewImage,
        disabled = false,
        testId,
        darkBg,
    } = props;

    const [filename, setFilename] = useState<null | string>(null);

    const handleDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (onDrop && !disabled) {
                if (acceptedFiles[0]) {
                    const file = acceptedFiles[0];
                    if (file.name) setFilename(file.name);
                }

                onDrop(acceptedFiles);
            }
        },
        [disabled, onDrop]
    );
    const handleClear = useCallback(() => {
        if (!disabled) {
            setFilename('');
            if (onClear) {
                onClear();
            }
        }
    }, [disabled, onClear]);

    const { getRootProps, getInputProps, isDragActive, isDragAccept } = useDropzone({
        onDrop: handleDrop,
        multiple: false,
        accept: accept,
    });

    const dashColor = useMemo(() => {
        return darkBg ? 'border-neutral-100' : 'border-neutral-500';
    }, [darkBg]);

    return (
        <section className="flex h-full w-full flex-col gap-0">
            <div
                data-test-id={`${testId}-drop-zone`}
                className={`group relative z-10 flex h-full w-full cursor-pointer flex-col items-center justify-center gap-3 border py-4 transition-all hover:bg-primary/10 ${
                    !isDragActive
                        ? `border-dashed ${dashColor}`
                        : isDragAccept
                        ? 'border-solid border-primary'
                        : 'border-solid border-danger bg-danger/10'
                }`}
                {...getRootProps()}
            >
                {/* Displayed the preview image, if set */}
                {previewImage && (
                    <div className="absolute inset-0 z-40 flex h-full w-full items-center justify-center bg-primary/50">
                        <Image
                            unoptimized={true}
                            src={previewImage}
                            layout={'fill'}
                            objectFit={'contain'}
                            alt="preview"
                            quality={80}
                            loading="lazy"
                            placeholder="blur"
                            // https://png-pixel.com/
                            blurDataURL="/images/blur/1x1-dcdcdc51.png"
                        />
                    </div>
                )}
                <input
                    {...getInputProps()}
                    className="hidden"
                    data-test-id={`${testId ?? nanoid()}-input`}
                />
                {icon && iconAccepted && iconNotAllowed && (
                    <span
                        className={`h-16 w-16 transition-all ${
                            !isDragActive
                                ? 'scale-110 text-neutral-300 group-hover:text-primary'
                                : isDragAccept
                                ? 'scale-110 animate-pulse text-primary'
                                : 'scale-110 animate-pulse text-danger'
                        } `}
                    >
                        {!isDragActive ? (
                            icon
                        ) : isDragAccept ? (
                            iconAccepted
                        ) : (
                            <span data-test-id={`${testId}-not-allowed`}>{iconNotAllowed}</span>
                        )}
                    </span>
                )}

                {/* Text Display */}
                {text && (
                    <span
                        className={`text-base transition-all ${
                            !isDragActive
                                ? 'text-neutral-300 group-hover:text-primary'
                                : isDragAccept
                                ? 'text-primary'
                                : 'text-danger'
                        }`}
                    >
                        {text ?? ''}
                    </span>
                )}
            </div>

            {/* HIDE if executeOnDrop === true !*/}
            {/* File Result Bar with Delete Button */}
            {filename && (
                <div
                    className={` ${executeOnDrop && 'hidden'}
                        relative flex-row items-center justify-center gap-2 bg-primary py-1 text-white
                    `}
                    data-test-id={`${testId}-dropped-file-name`}
                >
                    <DocumentIcon className="h-4" />
                    <span>{filename}</span>
                    <button
                        className="absolute top-2 right-2 z-40 flex h-4 w-4 items-center justify-center rounded-full bg-white p-0 text-primary hover:bg-danger hover:text-white"
                        onClick={() => {
                            handleClear();
                        }}
                    >
                        <XMarkIcon className="h-4" />
                    </button>
                </div>
            )}
        </section>
    );
};

export default React.memo(DropZone);
