import { IProduct } from '../../../../../interfaces/product/IProduct';
import { useMemo } from 'react';
import { useProductsAllQuery } from '../../../../general/hooks/product/useProductsAllQuery';
import { useRecoilValue } from 'recoil';
import { tenantIDSelector } from '../../../../general/state/tenantState';
import { IProductsGetInput } from '../../../../../interfaces/product/api/IProductsGetInput';
import Fuse from 'fuse.js';
import { useSearchState } from '../state/search.state';
import { useLocalisationState } from '../../../../general/Translations/state/localisation.state';

// Extended product type for search functionality
interface ISearchableProduct extends IProduct {
    localizedName: string;
    localizedDescription: string | null;
}

export const useSearchFilteredProducts = () => {
    const tenantID = useRecoilValue(tenantIDSelector);
    const { searchTerm } = useSearchState();
    const { userCurrentLanguage } = useLocalisationState();

    const currentLocale = userCurrentLanguage?.languageCode ?? 'de-DE';

    const productsInput = useMemo((): IProductsGetInput => {
        return {
            tenantID: tenantID ? tenantID : undefined,
            take: 300,
            skip: 0,
        };
    }, [tenantID]);

    const { data: products, isFetching: isFetchingProducts } = useProductsAllQuery(productsInput);

    // Prepare products with translations for search
    const searchableProducts = useMemo(() => {
        if (!products) return [];

        return products.map(product => {
            const searchableProduct = { ...product } as ISearchableProduct;
            
            // Handle name translations
            if (product.nameTranslation?.translations) {
                const nameTranslation = product.nameTranslation.translations.find(
                    t => t.languageCode === currentLocale
                );
                
                if (nameTranslation) {
                    searchableProduct.localizedName = nameTranslation.translation;
                } else {
                    searchableProduct.localizedName = product.nameTranslation.baseTranslation || product.name;
                }
            } else {
                searchableProduct.localizedName = product.name;
            }
            
            // Handle description translations
            if (product.descriptionTranslation?.translations) {
                const descriptionTranslation = product.descriptionTranslation.translations.find(
                    t => t.languageCode === currentLocale
                );
                
                if (descriptionTranslation) {
                    searchableProduct.localizedDescription = descriptionTranslation.translation;
                } else {
                    searchableProduct.localizedDescription = product.descriptionTranslation.baseTranslation || product.description;
                }
            } else {
                searchableProduct.localizedDescription = product.description;
            }
            
            return searchableProduct;
        });
    }, [products, currentLocale]);

    const filteredProducts: IProduct[] = useMemo(() => {
        if (searchTerm && products && searchableProducts.length > 0) {
            const fuse = new Fuse(searchableProducts, {
                keys: [
                    // Original fields
                    'name', 
                    'description',
                    // Base translations
                    'nameTranslation.baseTranslation',
                    'descriptionTranslation.baseTranslation',
                    // Localized fields
                    'localizedName',
                    'localizedDescription'
                ],
                shouldSort: true,
                includeScore: true,
                threshold: 0.4,
                distance: 100,
                findAllMatches: false,
                minMatchCharLength: 3,
            });

            const fuzzed = fuse.search(searchTerm);

            const filtered: IProduct[] = [];

            // Map to IProducts[]
            fuzzed.forEach((item) => {
                // Return original product (without the added localized fields)
                const originalProduct = products.find(p => p.id === item.item.id);
                if (originalProduct) {
                    filtered.push(originalProduct);
                }
            });
            return filtered;
        }

        return [];
    }, [searchTerm, products, searchableProducts]);

    return { filteredProducts, isFetchingProducts };
};
