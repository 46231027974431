import { gql } from 'graphql-request';
import { useApi } from '../api/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { cmsSlugSelector } from '../../../frontend/areas/CMSPage/state/CMSPage.state';

interface ContentLayoutItemRemoveImageInput {
    itemID: string;
    assetID: string;
}

export const useContentLayoutItemRemoveImageMutation = () => {
    const { postGql } = useApi();

    const queryClient = useQueryClient();
    const slug = useRecoilValue(cmsSlugSelector);

    return useMutation<boolean, unknown, ContentLayoutItemRemoveImageInput>({
        mutationFn: async (input) => {
            const { contentLayoutItemRemoveImage: result } = await postGql<{
                contentLayoutItemRemoveImage: boolean;
            }>(
                'contentLayoutItemRemoveImage',
                gql`
                    mutation ($itemID: ID!, $assetID: ID!) {
                        contentLayoutItemRemoveImage(itemID: $itemID, assetID: $assetID)
                    }
                `,
                input
            );

            return result;
        },
        onSuccess: () => {
            const key = [`contentPage-${slug ?? '-'}`];
            void queryClient.invalidateQueries({ queryKey: key });
        },
    });
};
