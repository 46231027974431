import { useCMSImageUploaderState } from '../state/CMSImageUploader.state';
import { IMedia } from '../../../../../interfaces/cart/productInCart';
import { useRecoilValue } from 'recoil';
import { cmsSlugSelector } from '../../../areas/CMSPage/state/CMSPage.state';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from '../../../../general/Translations/hooks/useTranslation';
import { useLocalisationState } from '../../../../general/Translations/state/localisation.state';
import { useContentLayoutItemAddImageMutation } from '../../../../general/hooks/pageCMSLayouts/useContentLayoutItemAddImage';
import { useContentLayoutItemRemoveImageMutation } from '../../../../general/hooks/pageCMSLayouts/useContentLayoutItemRemoveImage';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

export const useCMSImageModalUtil = () => {
    const {
        media,
        mediaTranslations,
        mediaTranslation,
        layout,
        itemIndex,
        hasContentAccess,
        defaultLanguage,
    } = useCMSImageUploaderState();

    const slug = useRecoilValue(cmsSlugSelector);
    const queryClient = useQueryClient();

    const { setData } = useCMSImageUploaderState();

    const { getT } = useTranslation();

    const { tenantDefaultLanguage } = useLocalisationState();

    const { mutateAsync: addMutation } = useContentLayoutItemAddImageMutation();
    const { mutateAsync: removeMutation } = useContentLayoutItemRemoveImageMutation();

    const handleClear = useCallback(() => {
        // This is required by DropZone but we're handling file uploads directly
    }, []);

    const handleDelete = useCallback(
        (mediaId: string, languageCodeToDelete?: string) => {
            if (!layout || itemIndex === undefined) return;
            if (confirm(getT('confirmAreYouSure') ?? 'Sind Sie sicher?')) {
                removeMutation({
                    itemID: layout?.items[itemIndex]?.itemID ?? '',
                    assetID: mediaId,
                })
                    .then(() => {
                        if (!layout) return;

                        // Update the local state to reflect changes immediately
                        if (!languageCodeToDelete) {
                            // Default language media
                            const updatedMedia = media?.filter((m) => m.id !== mediaId);
                            setData({ media: updatedMedia });
                        } else {
                            // For translations, we need to update both structures

                            // 1. Update mediaTranslations array if it exists
                            if (mediaTranslations?.length) {
                                const updatedTranslations = mediaTranslations.filter(
                                    (m) => m.id !== mediaId
                                );
                                setData({
                                    mediaTranslations: updatedTranslations,
                                });
                            }

                            // 2. Update mediaTranslation nested structure if it exists
                            if (mediaTranslation?.length) {
                                const updatedTranslationObjects = mediaTranslation.map((t) => {
                                    if (t.languageCode === languageCodeToDelete) {
                                        // Filter out the deleted media from this language's media array
                                        return {
                                            ...t,
                                            media: t.media.filter((m) => m.id !== mediaId),
                                        };
                                    }
                                    return t;
                                });

                                setData({
                                    mediaTranslation: updatedTranslationObjects,
                                });
                            }
                        }

                        void queryClient.invalidateQueries({
                            queryKey: [`contentPage-${slug ?? ''}`],
                            stale: true,
                        });

                        toast.success(
                            getT('toastSuccessImageDeleted') ?? 'Bild erfolgreich gelöscht'
                        );
                    })
                    .catch((reason) => {
                        console.error(reason);
                        toast.error(
                            getT('toastErrorWhenDeletingImage') ?? 'Fehler beim Löschen des Bildes.'
                        );
                    });
            }
        },
        [
            layout,
            itemIndex,
            getT,
            removeMutation,
            queryClient,
            slug,
            media,
            setData,
            mediaTranslations,
            mediaTranslation,
        ]
    );

    const calculateThumbnailDimensions = useCallback(
        (width: number, height: number, maxSize: number = 160) => {
            if (width <= maxSize && height <= maxSize) {
                // Image is already smaller than maxSize, keep original dimensions
                return { width, height };
            }

            // Calculate aspect ratio
            const aspectRatio = width / height;

            if (width > height) {
                // Landscape orientation
                return {
                    width: maxSize,
                    height: Math.round(maxSize / aspectRatio),
                };
            } else {
                // Portrait or square orientation
                return {
                    width: Math.round(maxSize * aspectRatio),
                    height: maxSize,
                };
            }
        },
        []
    );

    const handleDrop = useCallback(
        (acceptedFiles: File[], tabLanguageCode?: string) => {
            if (!layout || itemIndex === undefined || !hasContentAccess) return;

            // Make sure we always have a language code since it's now required
            // We prioritize:
            // 1. The tab's language code (for non-default tabs)
            // 2. The default language from tenant state
            // 3. The defaultLanguage from our component props
            // 4. A hard-coded fallback of "de-DE"
            // Important languageCode === locale like de-DE not just "de"
            const languageCodeToUse =
                tabLanguageCode ??
                tenantDefaultLanguage?.languageCode ??
                defaultLanguage ??
                'de-DE';

            acceptedFiles.forEach((file) => {
                // Get existing media items for this language to determine the order
                const existingMedia = languageCodeToUse
                    ? mediaTranslations?.filter((m) => m.languageCode === languageCodeToUse)
                    : media;

                const orderNo = (existingMedia?.length || 0) + 1;

                addMutation({
                    file: file,
                    tag: 'media',
                    itemID: layout?.items[itemIndex]?.itemID ?? '',
                    languageCode: languageCodeToUse,
                    orderNo: orderNo,
                })
                    .then((response: IMedia) => {
                        // Update local state with the new media for immediate UI updates
                        if (!tabLanguageCode) {
                            // Default language media
                            const updatedMedia = [...(media || []), response];
                            setData({ media: updatedMedia });
                        } else {
                            // For translations, update both data structures

                            // 1. Update mediaTranslations array
                            const updatedTranslations = [...(mediaTranslations || []), response];
                            setData({
                                mediaTranslations: updatedTranslations,
                            });

                            // 2. Update mediaTranslation nested structure if it exists
                            if (mediaTranslation) {
                                const updatedTranslationObjects = [...(mediaTranslation || [])];
                                const existingIndex = updatedTranslationObjects.findIndex(
                                    (t) => t.languageCode === tabLanguageCode
                                );

                                if (
                                    existingIndex >= 0 &&
                                    updatedTranslationObjects[existingIndex]?.media
                                ) {
                                    // Update existing language entry
                                    const updatedMedia = [
                                        ...updatedTranslationObjects[existingIndex].media,
                                        response,
                                    ];
                                    updatedTranslationObjects[existingIndex] = {
                                        ...updatedTranslationObjects[existingIndex],
                                        media: updatedMedia,
                                    };
                                } else if (tabLanguageCode) {
                                    // Create new language entry
                                    updatedTranslationObjects.push({
                                        languageCode: tabLanguageCode,
                                        media: [response],
                                    });
                                }

                                setData({
                                    mediaTranslation: updatedTranslationObjects,
                                });
                            }
                        }

                        void queryClient.invalidateQueries({
                            queryKey: [`contentPage-${slug ?? ''}`],
                            stale: true,
                        });

                        toast.success(
                            getT('toastSuccessImageUploaded') ?? 'Bild erfolgreich hochgeladen'
                        );
                    })
                    .catch((reason) => {
                        console.error(reason);
                        toast.error(
                            getT('toastErrorWhenUploadingImage') ??
                                'Fehler beim Hochladen des Bildes'
                        );
                    });
            });
        },
        [
            layout,
            itemIndex,
            hasContentAccess,
            tenantDefaultLanguage?.languageCode,
            defaultLanguage,
            mediaTranslations,
            media,
            addMutation,
            queryClient,
            slug,
            getT,
            setData,
            mediaTranslation,
        ]
    );

    return {
        handleClear,
        handleDelete,
        handleDrop,
        calculateThumbnailDimensions,
    };
};
