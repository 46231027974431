import { create } from 'zustand';

interface ISearchState {
    show: boolean;
    setShow: (show: boolean) => void;
    searchTerm: string;
    setSearchTerm: (searchTerm: string) => void;
    set: (state: Partial<ISearchState>) => void;
}

export const useSearchState = create<ISearchState>((set) => ({
    show: false,
    setShow: (show) => {
        set({ show });
    },
    searchTerm: '',
    setSearchTerm: (searchTerm) => {
        set({ searchTerm });
    },
    set: (state) => {
        set(state);
    },
}));
