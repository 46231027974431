import React from 'react';
import Link from 'next/link';
import { useThemeStyles } from '../../../../general/theme/TenantTheme/hooks/useThemeStyles';
import { useTranslationApply } from '../../../../general/Translations/hooks/useTranslationApply';
import { useTranslation } from '../../../../general/Translations/hooks/useTranslation';
import { ETranslationType } from '../../../../general/Translations/enum/translations.enums';
import { ICategory } from '../../../../../interfaces/category/ICategory';

interface IFooterCategoryLink {
  category: ICategory;
  isParent?: boolean;
}

export const FooterCategoryLink: React.FC<IFooterCategoryLink> = ({ category, isParent = false }) => {
  const { applyStyles } = useThemeStyles();
  const { applyTranslation } = useTranslationApply();
  const { getTranslatedCategoryName } = useTranslation();
  
  // Use the nameTranslation if available, otherwise use name
  const displayName = getTranslatedCategoryName(category);
  
  return (
    <Link
      href={`/catalog?id=${category.id}`}
      className={isParent ? 
        "text-md font-semibold text-primary-dark" : 
        "text-sm text-primary transition-all hover:font-bold hover:tracking-wide"
      }
      style={{
        ...applyStyles({
          textColor: isParent ? 'colors.primary.dark' : 'colors.primary.DEFAULT',
        }),
      }}
      {...applyTranslation({
        key: category.id,
        type: ETranslationType.categoryName,
      })}
    >
      {displayName}
    </Link>
  );
};