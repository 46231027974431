import { useSearchState } from '../../../frontend/areas/search/state/search.state';
import { useSidebarMobileState } from '../../../frontend/areas/sidebarMobile/state/sidebarMobile.state';
import { useCallback, useEffect, useRef } from 'react';

export const useResetModals = () => {
    const { show: search, setShow: setSearch } = useSearchState();
    const { show: sidebar, setShow: setSidebar } = useSidebarMobileState();

    // Track which modal was most recently activated
    const lastActivatedRef = useRef<string | null>(null);

    const resetModals = useCallback(() => {
        setSearch(false);
        setSidebar(false);
    }, [setSearch, setSidebar]);

    // Watch search state
    useEffect(() => {
        if (search) {
            lastActivatedRef.current = 'search';
            // Only close other modals if search just opened
            setSidebar(false);
        }
    }, [search, setSidebar]);

    // Watch sidebar state
    useEffect(() => {
        if (sidebar) {
            lastActivatedRef.current = 'sidebar';
            // Only close other modals if sidebar just opened
            setSearch(false);
        }
    }, [sidebar, setSearch]);

    return { resetModals };
};
