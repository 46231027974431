import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../../../general/theme/ComputedStyles/recoil/computedStyles.recoil';
import { useThemeStyles } from '../../../../general/theme/TenantTheme/hooks/useThemeStyles';
import { useTranslationApply } from '../../../../general/Translations/hooks/useTranslationApply';
import { useTranslation } from '../../../../general/Translations/hooks/useTranslation';
import { loggedInUserSelector } from '../../../../general/state/currentUser';
import { getUsersFullName, getUsersShortName } from '../../../../../helper/getUserNames';
import { SidebarLanguageSelectorAdapter } from './SidebarLanguageSelectorAdapter';

export const SidebarUserDisplay: React.FC = () => {
    const tenantTheme = useRecoilValue(getTenantTheme);
    const user = useRecoilValue(loggedInUserSelector);

    const { applyStyles } = useThemeStyles();
    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();

    const userName: string = useMemo(() => {
        if (!user) return '';

        const fullName = getUsersFullName(user);
        const shortName = getUsersShortName(user);

        if (fullName.length > 20) {
            return shortName;
        }

        return fullName;
    }, [user]);

    return (
        <section className="relative flex flex-row bg-neutral-200/50 p-4 gap-2 h-full">
            <div className="relative flex flex-row gap-2 w-full items-center">
                {tenantTheme.header?.showAvatar && user && (
                    <div className="flex flex-col items-center justify-center aspect-[1/1] h-[40px] w-[40px] md:h-[45px] md:w-full">
                        <img
                            src={user?.picture ? user?.picture : '/assets/svg/defaultAvatar.svg'}
                            alt=""
                            width={45}
                            height={45}
                            className="w-full object-cover"
                            data-test-id="user-header-avatar"
                        />
                    </div>
                )}

                {tenantTheme.header?.showUserDetails && (
                    <div className="relative flex flex-col items-start justify-center">
                        <span
                            className={`text-body-light`}
                            data-test-id="user-header-welcome"
                            {...applyTranslation({
                                key: 'headerWelcome',
                            })}
                            style={{
                                ...applyStyles({
                                    textColor:
                                        tenantTheme.colors.applications.header.text
                                            .userContextLight,
                                }),
                            }}
                        >
                            {getT('headerWelcome') ?? 'Herzlich willkommen'}
                        </span>
                        <span
                            className={`font-bold text-body-dark`}
                            data-test-id="user-header-username"
                            style={{
                                ...applyStyles({
                                    textColor:
                                        tenantTheme.colors.applications.header.text
                                            .userContextPrimary,
                                }),
                            }}
                        >
                            {userName}
                        </span>
                    </div>
                )}
            </div>
            <div className="relative flex h-full w-10 items-center justify-center">
                <SidebarLanguageSelectorAdapter />
            </div>
        </section>
    );
};
