import React from 'react';
import { TextField } from '../../../general/controls/texfield/TextField';
import { useSearchState } from './state/search.state';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';

export const SearchField: React.FC = () => {
    const { searchTerm, setSearchTerm } = useSearchState();

    return (
        <div>
            <TextField
                placeholderTranslationKey={'headerSearchPlaceholder'}
                iconStart={<MagnifyingGlassIcon className="h-5 w-5" />}
                onChange={(value) => {
                    setSearchTerm(value);
                }}
                value={searchTerm}
                clearable={true}
            />
        </div>
    );
};
