import React, { useCallback } from 'react';
import { EditorModal } from '../../../../general/modals/EditorModal';
import { useCMSImageUploaderState } from '../state/CMSImageUploader.state';
import { useTranslation } from '../../../../general/Translations/hooks/useTranslation';
import { Typography } from '../../../../general/typo/Typography/Typography';
import { useLanguageName } from '../../../../general/hooks/localisation/useLanguageName';
import { FlagSymbol } from '../../../../general/elements/FlagSymbol/FlagSymbol';
import { Chip } from '../../../../general/elements/Chips/Chip';
import { Infobox } from '../../../../general/display/infobox/Infobox';
import { CMSImageUploadModalItem } from './CMSImageUploadModalItem';

export const CMSImageUploadModal: React.FC = () => {
    const { show, hasContentAccess, availableLanguages, defaultLanguage, reset } =
        useCMSImageUploaderState();
    const { getT } = useTranslation();

    const { getLanguageName } = useLanguageName('de-DE');

    const handleClose = useCallback(() => {
        reset();
    }, [reset]);

    if (!show || !hasContentAccess) return null;

    return (
        <EditorModal
            show={show}
            closeFunction={handleClose}
            headline={getT('imageUploadHeadline') ?? 'Bilder verwalten'}
            width="narrow"
        >
            <div className="flex flex-col gap-6 overflow-y-auto px-2 py-4">
                {/* Default Language Section */}
                <div className="mb-8 flex flex-col gap-4">
                    {defaultLanguage && (
                        <Typography variant="h4" className="text-lg font-medium">
                            <div className="relative flex flex-row items-center gap-2">
                                <FlagSymbol countryCode={defaultLanguage} maxHeight={20} />
                                <Typography variant="h3" className="text-lg font-medium">
                                    {getLanguageName(defaultLanguage)}
                                </Typography>
                                <Chip color="info">Standardbild</Chip>
                            </div>
                        </Typography>
                    )}
                    <div className="max-w-[500px]">
                        <Infobox small={true}>
                            Dieses Bild dient immer als Standardbild, wenn sonst kein Bild vorhanden
                            ist. Es sollte somit immer gesetzt sein.
                        </Infobox>
                    </div>
                    <CMSImageUploadModalItem />
                </div>

                <Typography variant="h3">Alternative Bilder pro Sprache</Typography>
                <div className="max-w-[500px]">
                    <Infobox small={true}>
                        Diese Bilder werden verwendet, wenn der Benutzer eine passende Sprache
                        ausgewählt hat.
                    </Infobox>
                </div>

                {/* Other Languages Sections */}
                {availableLanguages
                    .filter((lang) => lang.languageCode !== defaultLanguage)
                    .map((lang) => (
                        <div key={lang.languageCode} className="flex flex-col gap-4">
                            <div className="relative flex flex-row items-center gap-2">
                                <FlagSymbol countryCode={lang.languageCode} maxHeight={20} />
                                <Typography variant="h4" className="text-lg font-medium">
                                    {getLanguageName(lang.languageCode)}
                                </Typography>
                            </div>
                            <CMSImageUploadModalItem languageCode={lang.languageCode} />

                            <hr className="my-2 border-b border-neutral-300" />
                        </div>
                    ))}
            </div>
        </EditorModal>
    );
};
