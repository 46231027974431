import { useMemo } from 'react';
import { clientAppUserMenuItems, faqMenuItem } from '../../../../../mock/userMenuItems';
import { useTenantFields } from '../../../../general/hooks/tenant/useTenantFields';

export const useSidebarMenuItems = () => {
    const { getTenantField } = useTenantFields();

    const navItems = useMemo(() => {
        const items = [...clientAppUserMenuItems];

        if (getTenantField('showFAQInMenu')) {
            // Add to second position
            items.splice(items.length - 1, 0, faqMenuItem);
        }

        return items;
    }, [getTenantField]);

    return { navItems };
};
