import React, { PropsWithChildren, useMemo } from 'react';
import { Header, IHeader } from '../frontend/areas/header/Header';
import { Footer } from '../frontend/areas/footer/Footer';
import { ComputedStylesProvider } from '../general/theme/ComputedStyles/ComputedStylesProvider';
import { ShopPageTransition } from '../general/layout/PageTransitions/ShopPageTransition';
import { useRouter } from 'next/router';
import { AnimatePresence } from 'framer-motion';
import { useDefaultTenantBlockFrontend } from '../general/hooks/user/useDefaultTenantBlockFrontend';
import useTranslationObserver from '../general/Translations/hooks/useTranslationObserver';
import { TranslationEditor } from '../general/Translations/TranslationEditor';
import { useLanguageControls } from '../general/Translations/hooks/useLanguageControls';
import { AdminControlsFAB } from '../general/elements/adminControls/AdminControlsFAB';
import { useShowControls } from '../general/elements/adminControls/hooks/useShowControls';
import { FavIconTenant } from '../general/elements/FavIconTenant/FavIconTenant';
import { CMSImageUploadModal } from '../frontend/elements/CMSMediaUploader/elements/CMSImageUploadModal';
import { useWindowDimensions } from '../general/hooks/helper/useWindowDimensions';
import { HeaderMobile } from '../frontend/areas/headerMobile/HeaderMobile';
import { FooterMobile } from '../frontend/areas/footerMobile/FooterMobile';
import { SidebarMobile } from '../frontend/areas/sidebarMobile/SidebarMobile';
import { useEnvironment } from '../general/hooks/helper/useEnvironment';
import { SearchModal } from '../frontend/areas/search/SearchModal';
import { useResetModals } from '../general/hooks/resetModals/useResetModals';

export interface IStageWrapper extends PropsWithChildren {
    testId?: string;
    header?: IHeader;
    contentFullWidth?: boolean;
    showFooter?: boolean;
    noPadding?: boolean;
    lockHeightToScreen?: boolean;
}

export const StageWrapper: React.FC<IStageWrapper> = (props) => {
    const {
        children,
        testId,
        header,
        contentFullWidth = false,
        showFooter = true,
        noPadding = false,
        lockHeightToScreen = false,
    } = props;

    const { pathname } = useRouter();

    useDefaultTenantBlockFrontend();

    useTranslationObserver();

    useLanguageControls();

    useShowControls();

    useResetModals();

    const { isDesktop } = useWindowDimensions();

    const { isDevelopment } = useEnvironment();

    const contentWidthStyles = useMemo((): string => {
        return contentFullWidth ? 'max-w-full' : 'max-w-7xl';
    }, [contentFullWidth]);

    const contentPaddingStyles = useMemo((): string => {
        return noPadding ? 'p-0' : 'px-3 py-4 md:px-4 md:py-8';
    }, [noPadding]);

    const wrapperHeightStyles = useMemo((): string => {
        if (lockHeightToScreen) return 'h-screen max-h-screen overflow-y-hidden';

        return 'min-h-screen';
    }, [lockHeightToScreen]);

    return (
        <ComputedStylesProvider>
            <FavIconTenant />
            <div className="relative">
                {/* Header */}
                <div className="sticky top-0 left-0 z-30 mx-auto w-full md:h-headerTotalDefault">
                    {(isDesktop || !isDevelopment) && <Header {...header} />}
                    {!isDesktop && isDevelopment && <HeaderMobile {...header} />}
                </div>
                <div
                    className={`relative w-screen  ${wrapperHeightStyles}`}
                    key="wrapper-ref"
                    id="stageWrapper"
                    data-test-id={testId}
                >
                    <AnimatePresence mode="wait">
                        <ShopPageTransition key={pathname}>
                            <div className="relative flex w-full flex-col">
                                <div
                                    className={`relative mx-auto w-full md:min-h-screen ${contentWidthStyles} ${contentPaddingStyles}`}
                                >
                                    {children}
                                </div>

                                {showFooter && (isDesktop || !isDevelopment) && <Footer />}
                                {showFooter && !isDesktop && isDevelopment && <FooterMobile />}
                            </div>
                        </ShopPageTransition>
                    </AnimatePresence>
                    <TranslationEditor />
                    <CMSImageUploadModal />
                </div>

                <AdminControlsFAB />
            </div>
            {!isDesktop && <SidebarMobile />}
            <SearchModal />
        </ComputedStylesProvider>
    );
};

