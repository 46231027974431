import React, { useMemo } from 'react';
import { LanguageSelector } from '../../../../general/controls/LanguageSelector/LanguageSelector';
import { useLocalisationState } from '../../../../general/Translations/state/localisation.state';
import { ILocale } from '../../../../../interfaces/localisation/locale.interfaces';
import { toast } from 'react-toastify';
import { useUpdateUserLanguage } from '../../../../general/hooks/localisation/useUpdateUserLanguage';
import { useTranslation } from '../../../../general/Translations/hooks/useTranslation';

export const SidebarLanguageSelectorAdapter: React.FC = () => {
    const { updateUserLanguage } = useUpdateUserLanguage();

    const { userLanguageOptions, userCurrentLanguage, tenantDefaultLanguage } =
        useLocalisationState();

    const defaultLanguage = useMemo(() => {
        return (
            userCurrentLanguage ||
            tenantDefaultLanguage || { languageCode: 'de-DE', default: false }
        );
    }, [tenantDefaultLanguage, userCurrentLanguage]);

    const { getT } = useTranslation();

    const handleLanguageSelect = (locale: ILocale) => {
        void updateUserLanguage(locale.languageCode)
            .then(() => {})
            .catch(() => {
                toast.error(
                    getT('toastErrorLanguageNotUpdated') ??
                        'Sprache konnte nicht aktualisiert werden',
                    {
                        toastId: 'general-language-update-error',
                        autoClose: 2000,
                    }
                );
            });
    };

    return (
        <>
            {userLanguageOptions.length > 0 && (
                <LanguageSelector
                    currentLanguage={defaultLanguage}
                    openToVertical="up"
                    openToSide="left"
                    onSelect={(value) => void handleLanguageSelect(value)}
                    options={userLanguageOptions}
                    selected={defaultLanguage}
                    flagOnly={true}
                />
            )}
        </>
    );
};
