import { useEffect, useState } from 'react';
import { useAdminControlsState } from '../state/adminControls.state';
import { EAccess } from '../../../../../interfaces/role/IAccess';
import { useAccess } from '../../../access/hooks/useAccess';

export const useShowControls = () => {
    const { showFAB, setShowFAB, setCmsControls, setProductControls, setLanguageControls } =
        useAdminControlsState();

    const [init, setInit] = useState(false);

    const { hasAccess } = useAccess();

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
            const keyToCheck = isMac ? e.metaKey : e.ctrlKey;

            if (keyToCheck && e.key === 'l') {
                e.preventDefault();
                const adminControlsState = useAdminControlsState.getState();
                
                if (showFAB) {
                    // Use reset which now also saves state before resetting
                    adminControlsState.reset();
                } else {
                    // Turn on FAB and restore previous controls state
                    setShowFAB(true);
                    adminControlsState.restoreControlsState();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [showFAB, setShowFAB]);

    useEffect(() => {
        if (!init) {
            // FAB
            if (hasAccess([EAccess.gAdminArea])) {
                const showFABButton = localStorage.getItem('showFAB');
                if (localStorage.getItem('showFAB')) {
                    setShowFAB(showFABButton === 'true');
                }
            }

            if (hasAccess([EAccess.mProd])) {
                // Product Controls
                const productControls = localStorage.getItem('productControls');
                if (localStorage.getItem('productControls')) {
                    setProductControls(productControls === 'true');
                }
            }

            if (hasAccess([EAccess.mLocalisation])) {
                // Language Controls
                const languageControls = localStorage.getItem('languageControls');
                if (localStorage.getItem('languageControls')) {
                    setLanguageControls(languageControls === 'true');
                }
            }

            if (hasAccess([EAccess.mContent])) {
                // CMS Controls
                const cmsControls = localStorage.getItem('cmsControls');
                if (localStorage.getItem('cmsControls')) {
                    setCmsControls(cmsControls === 'true');
                }
            }

            setInit(true);
        }
    }, [hasAccess, init, setCmsControls, setLanguageControls, setProductControls, setShowFAB]);
};
