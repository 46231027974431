import { create } from 'zustand';

interface ISidebarMobile {
    show: boolean;
    setShow: (show: boolean) => void;
    set: (state: Partial<ISidebarMobile>) => void;
}

export const useSidebarMobileState = create<ISidebarMobile>((set) => ({
    show: false,
    setShow: (show) => {
        set({ show });
    },
    set: (state) => {
        set(state);
    },
}));
